import { CollectionApi } from '@jumpcloud-ap/collection-api';

export class OrganizationsApi extends CollectionApi {
  constructor() {
    super('/api/organizations', {
      limit: 50,
      skip: 0,
      sortIgnoreCase: 'settings.name',
    });
  }
}

export default new OrganizationsApi();
