<template>
  <HeaderApp
    v-if="isAnMsp"
    data-test-id="MtpNavigationBar"
    logoLink="img/logoUndefined.png?url"
  >
    <template v-slot:logo>
      <img
        v-if="orgLogo"
        alt="logo"
        :class="$style.logoImg"
        :src="orgLogo"
      >
      <div v-else />
    </template>
    <template v-slot:menu>
      <DropdownActions
        :buttonText="dropdownMenuTitle"
        :class="$style.mtpDropdownMenu"
        :items="menuSections"
      />
    </template>
  </HeaderApp>
</template>

<script>
import {
  DropdownActions, HeaderApp, Types,
} from '@jumpcloud/ui-components';
import { mapActions, mapGetters } from 'vuex';
import { replacePageLocation } from '@/utils/replacePageLocation';
import ClearMspState from '@/utils/ClearMspState.js';
import IntervalService from '@/utils/IntervalService.ts';
import MtpUtil from '@/utils/MtpUtil';
import OrganizationsCollection from '@/store/modules/OrganizationsCollection';

const DashboardConsts = {
  dashboardMenuItem: 'Multi-Tenant Portal Dashboard',
  locationLabel: 'Dashboard',
  menuTitle: 'Multi-Tenant Portal',
};

const jcUndefinedLogo = 'img/logoUndefined.png?url';

const { DropdownMenuItem } = Types;

export default {
  name: 'MtpNavigationBar',

  components: {
    DropdownActions,
    HeaderApp,
  },

  props: {
    showJcLogo: Boolean,
  },

  data: () => ({
    dropdownMTPTitle: DashboardConsts.menuTitle,
    keepMenuFreshErrorCount: 0,
    keepMenuFreshInterval: null,
  }),

  computed: {
    ...mapGetters('MtpNavBarOrganizationsCollection', {
      organizations: 'getCollection',
    }),

    ...mapGetters('CoreUserInfoModule', {
      name: 'role',
      currentOrgId: 'orgId',
      isAnMsp: 'isAnMsp',
    }),

    ...mapGetters('RouteInfoModule', [
      'fullPath',
      'meta',
    ]),

    dropdownMenuTitle() {
      return `${this.orgDisplayName} | ${this.dropdownMTPTitle}`;
    },

    matchedOrg() {
      return this.organizations.find(org => org.id === this.currentOrgId) ?? null;
    },

    menuSections() {
      const menuItems = [
        DropdownMenuItem({
          text: DashboardConsts.dashboardMenuItem,
          href: '/mtp',
        }),
      ];

      const { location } = window;
      const organizationMenuItems = this.organizations
        .filter(organization => organization._id !== this.currentOrgId)
        .map((organization) => {
          const name = organization.displayName || '[ org name not set ]';
          const logo = organization.logoUrl || jcUndefinedLogo;
          const orgId = organization._id;
          const hash = MtpUtil.getMtpOrgSwitchingRedirectHash(
            {
              path: this.fullPath,
              meta: this.meta,
            },
            location,
          );

          return DropdownMenuItem({
            text: name,
            click: () => {
              this.handleItemClick(`/${hash}?jc-orgId=${orgId}&orgName=${name}&orgLogo=${logo}`);
            },
          });
        });

      if (organizationMenuItems.length > 0) {
        menuItems.push(
          DropdownMenuItem({
            separator: true,
          }),
        );
      }

      return [...menuItems, ...organizationMenuItems];
    },

    orgDisplayName() {
      return this.matchedOrg?.displayName ?? DashboardConsts.locationLabel;
    },

    orgLogo() {
      return this.matchedOrg?.logoUrl ?? null;
    },
  },

  created() {
    if (!(this.$store && this.$store.state && this.$store.state.MtpNavBarOrganizationsCollection)) {
      this.$store.registerModule(
        'MtpNavBarOrganizationsCollection',
        OrganizationsCollection,
      );
    }
    this.fetchCollection();
  },

  mounted() {
    this.startKeepMenuFresh();
  },

  destroyed() {
    this.cancelKeepMenuFresh();

    if (this.$store.hasModule('MtpNavBarOrganizationsCollection')) {
      this.$store.unregisterModule('MtpNavBarOrganizationsCollection');
    }
  },

  methods: {
    ...mapActions('MtpNavBarOrganizationsCollection', [
      'fetchCollection',
      'refreshCollection',
    ]),

    cancelKeepMenuFresh() {
      IntervalService.clear(this.keepMenuFreshInterval);
      this.keepMenuFreshInterval = null;
    },

    getQueryStringParameter(key, queryString = window.location.search) {
      const params = new URLSearchParams(queryString.substring(1));

      return params.get(key);
    },

    handleItemClick(item) {
      const action = item;

      if (action === '/mtp') {
        ClearMspState.clearStorage();
        return;
      }

      replacePageLocation(action);
    },

    handleKeepMenuFresh() {
      const handleRefreshError = () => {
        this.keepMenuFreshErrorCount += 1;

        if (this.keepMenuFreshErrorCount >= 3) this.cancelKeepMenuFresh();
      };

      this.refresh().catch(handleRefreshError.bind(this));
    },

    refresh() {
      return this.refreshCollection();
    },

    startKeepMenuFresh() {
      const keepFreshInterval = 30 * 1000;
      this.cancelKeepMenuFresh();
      this.keepMenuFreshInterval = IntervalService
        .set(() => this.handleKeepMenuFresh(), keepFreshInterval);
    },
  },
};
</script>

<style scoped module>
.logoImg {
  fill: currentColor;
  flex: 0 0 auto;
  height: 25px;
  position: relative;
  width: auto;
}

.logoImg:focus {
  outline: none;
}

.mtpDropdownMenu {
  z-index: 1002;
}

.mtpDropdownMenu /deep/ button {
  max-width: 800px;
}

.mtpDropdownMenu /deep/ ul {
  left: unset;
  max-height: 500px;
  overflow-y: auto;
  right: 0;
  width: auto;
}
</style>
