import { EventsPlugin } from '@jumpcloud/ui-events-vue';
import { TestIdMixin } from '@jumpcloud-ap/test-id';
import { buildUiEventsOptions } from '@jumpcloud-ap/ui-events-setup';
import { featureTrialModuleName as featureTrialModule } from '@jumpcloud-ap/feature-trials';
import { initializeFlags } from './feature-flags';
import { store } from './store/index';
import { useCoreUserInfo } from '@jumpcloud-ap/core-user-info';
import InactivityModalController from '@/components/InactivityModalController';
import PeripheralUI from './PeripheralUI.vue';
import Vue from 'vue';

Vue.mixin(TestIdMixin);

Vue.use(EventsPlugin, buildUiEventsOptions());

let app: Vue;

export const destroy = () => {
  if (app) {
    app.$destroy();
  }
};

export const initialize = async () => {
  if (app) {
    destroy();
  }

  const {
    updateLastActiveTime,
  } = InactivityModalController;

  const coreInfo = useCoreUserInfo();

  await (async () => {
    const settings = await fetch('/ui-settings.json').then(r => r.json());

    await initializeFlags({
      disableStreaming: settings.launchDarklyDisableStreaming,

      identity: {
        id: coreInfo.isAnMsp.value
          ? coreInfo.providerId.value ?? coreInfo.orgId.value!
          : coreInfo.orgId.value!,

        name: coreInfo.name.value ?? '',
      },

      launchDarklyConfig: {
        baseUrls: settings.launchDarklyBaseUrls,
        clientSideId: settings.launchDarklyClientSideId,
      },

      source: settings.launchDarklySource === 'localStorage'
        ? 'localStorage'
        : 'launchDarkly',
    });
  })();

  await store.dispatch(`${featureTrialModule}/initializeTrials`, null, { root: true });

  updateLastActiveTime();

  const container = document.getElementById('single-spa-application:@jumpcloud-ap/peripheralui_app')!;
  const root = document.createElement('div');
  container.appendChild(root);

  [app] = new Vue({
    el: root,
    name: 'PeripheralUIAppContainer',
    render(h) { return h(PeripheralUI); },
    store,
  }).$children;

  coreInfo.cleanup();
};
